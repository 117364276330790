import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";
import { apiFilterNonNull } from "./apiFilterNonNull";
import { localLogger, logger } from "../util/logger";

// Start OAuth process for signing up/in with provider
// This is not absolute, but is called from <SignupModal/> => <AuthSection/> => <Auth/> => <AuthSocial/> => signInWithProvider from auth.js
export const startOAuthProcess = async (
  provider,
  params,
  afterAuthPath = "",
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) => {
  try {
    apiValidateArguments({ provider, adminToken });

    const validProviders = ["github", "google", "github.com", "google.com"];

    if (!validProviders.includes(provider)) {
      throw new Error(
        `Invalid provider—expecting one of: ${validProviders.join(", ")}`,
        provider
      );
    }

    // Capture the current location's pathname and search params (query string)
    const currentRoute =
      afterAuthPath || window.location.pathname + window.location.search;

    // URL-encode it to make it safe for use in the URL
    const encodedRoute = encodeURIComponent(currentRoute);

    // Base URL and provider string
    const baseUrl = `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/get-auth/oauth`;
    const normalizedProvider = provider.replace(".com", "");
    const providerString = `provider_name=${normalizedProvider}.com`;

    // Add params if it exists and is not already a query string
    if (typeof params === "string" && !params.startsWith("?")) {
      params = `?${params}`;
    }

    // Construct the URL with the route as an encoded parameter
    const url = `${baseUrl}${
      params ? `${params}&` : "?"
    }${providerString}&initiation_route=${encodedRoute}`;

    const response = await apiRequestExternal(
      url,
      "GET",
      null,
      { Authorization: adminToken },
      true,
      false
    );

    localLogger("response in startOAuthProcess function:", response);

    const oAuthUrl = response.data.authorization_url;

    window.location.href = oAuthUrl;
  } catch (err) {
    console.error("Error initiating OAuth:", err);
    throw err;
  }
};

// Submit email validation token
export const submitEmailToken = async (
  email,
  emailToken,
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) => {
  try {
    // Validate args
    apiValidateArguments({ email, emailToken, adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/get-auth/validate_account_validation_token`,
      "POST",
      { email: email, token: emailToken },
      { Authorization: adminToken },
      false,
      false
    );

    localLogger("Response in submitEmailToken function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in submitEmailToken function:", err);
    throw err;
  }
};

// Resend email validation token email
export const resendEmailToken = async (
  email,
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) => {
  try {
    // Validate args
    apiValidateArguments({ email, adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/get-auth/email_account_validation_token`,
      "POST",
      { email },
      { Authorization: adminToken },
      false,
      false,
      2
    );

    localLogger("Response in resendEmailToken function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in resendEmailToken function:", err);
    throw err;
  }
};

// get regions
export async function getRegions(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/supported_regions`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getRegions function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getRegions function:", error);
    throw error;
  }
}

// create account / register
export async function createUser(
  email,
  password,
  params,
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    apiValidateArguments({ email, password, adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${
        process.env.NEXT_PUBLIC_COMPOSER_URL
      }/v1/public/global/get-auth/register${params ? `${params}` : ""}`,
      "POST",
      { email, password },
      { Authorization: adminToken },
      false,
      false
    );

    localLogger("Response in createUser function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in createUser function:", error);
    throw error;
  }
}

// check access key
export async function checkAccessKey(
  accessKey,
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    apiValidateArguments({ accessKey, adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/early_access_check`,
      "POST",
      { early_access_key: accessKey },
      { Authorization: adminToken },
      false,
      false
    );

    localLogger("Response in checkAccessKey function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in checkAccessKey function:", error);
    throw error;
  }
}

// get public user profile info
export async function getUserProfile(
  token = process.env.NEXT_PUBLIC_PROJECT_ID,
  displayName
) {
  try {
    // Validate args
    if (!token) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/profile/public?display_name=${displayName}`,
      "GET",
      null,
      { Authorization: token },
      false,
      false
    );

    localLogger("Response in getUserProfile function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getUserProfile function:", error);
    throw error;
  }
}

// Get all avatar image urls for user profiles
export async function getAvatarLinks(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/profile/avatar_options`,
      "GET",
      null,
      { Authorization: accessToken },
      false,
      false
    );

    localLogger("Response in getAvatarLinks function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getAvatarLinks function:", error);
    throw error;
  }
}

// get user by Firebase Auth uid (via token)
export async function getUserByToken(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/profile/by_token`,
      "GET",
      null,
      { Authorization: accessToken },
      false,
      false
    );

    localLogger("Response in getUserByToken function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getUserByToken function:", error);
    throw error;
  }
}

// update user
export async function updateUser(
  accessToken,
  email,
  username,
  description,
  avatar,
  region
) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Filter to only pass provided args in request body
    const body = apiFilterNonNull({
      email,
      display_name: username,
      description,
      avatar,
      region,
    });

    if (Object.keys(body).length === 0) {
      throw new Error("No new data provided to update user.");
    }

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/profile`,
      "PUT",
      body,
      { Authorization: accessToken },
      false,
      false
    );

    localLogger("Response in updateUser function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in updateUser function:", error);
    throw error;
  }
}

// get all of a user's experiments
export async function getUserExperiments(accessToken, skip = 0, limit = 10) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/experiments?skip=${skip}&limit=${limit}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getUserExperiments function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getUserExperiments function:", error);
    throw error;
  }
}

// tell backend that user is active
export async function updateUserHeartbeat(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/user_session/heartbeat`,
      "PUT",
      null,
      { Authorization: accessToken }
    );

    logger("Response in updateUserHeartbeat function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in updateUserHeartbeat function:", error);
    throw error;
  }
}

// fetch latest user roles state
export async function getUserRoles(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/roles`,
      "GET",
      null,
      { Authorization: accessToken },
      false,
      false
    );

    localLogger("Response in getUserRoles function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getUserRoles function:", error);
    throw error;
  }
}

// ask backend if user is active via other tabs/devices
export async function checkUserHeartbeat(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/user_session/last_active`,
      "POST",
      null,
      { Authorization: accessToken }
    );

    logger("Response in checkUserHeartbeat function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in checkUserHeartbeat function:", error);
    throw error;
  }
}

// Send validation token for new email of existing user
export const sendUserEmailToken = async (accessToken, email) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken, email });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/email_account_validation_token`,
      "POST",
      { email },
      { Authorization: accessToken }
    );

    localLogger("Response in sendUserEmailToken function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in sendUserEmailToken function:", err);
    throw err;
  }
};

// Change email
export const changeUserEmail = async (accessToken, email, emailToken) => {
  try {
    // Validate args
    apiValidateArguments({ email, emailToken, accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/update_user_email`,
      "POST",
      { email, token: emailToken },
      { Authorization: accessToken }
    );

    localLogger("Response in changeUserEmail function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in changeUserEmail function:", err);
    throw err;
  }
};

// Update tutorial progress
export const updateTutorialProgress = async (
  accessToken,
  completedChapterId
) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken, completedChapterId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/update_tutorial_progress`,
      "PUT",
      { completed_chapter_id: completedChapterId },
      { Authorization: accessToken }
    );

    localLogger("Response in updateTutorialProgress function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in updateTutorialProgress function:", err);
    throw err;
  }
};

export const unlockAdvanced = async (accessToken) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/show_advanced_tutorials`,
      "POST",
      { Authorization: accessToken }
    );

    localLogger("Response in unlockAdvanced function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in unlockAdvanced function:", err);
    throw err;
  }
};

// Search for a user
export const getUser = async (accessToken, displayName) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken, displayName });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/query?display_name=${displayName}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    localLogger("Response in getUser function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in getUser function:", err);
    throw err;
  }
};

// Stop all user experiments/plays
export const shutEmDown = async (accessToken) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/stop_running_sessions`,
      "POST",
      null,
      { Authorization: accessToken }
    );

    localLogger("Response in shutEmDown function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in shutEmDown function:", err);
    throw err;
  }
};

// Confirm agreement to privacy/legal checkboxes
export const givePrivacyConsent = async (accessToken) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/consent_to_latest_terms_and_policy`,
      "POST",
      { Authorization: accessToken }
    );

    localLogger("Response in givePrivacyConsent function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in givePrivacyConsent function:", err);
    throw err;
  }
};

export const getAPIKey = async (accessToken) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/api_key`,
      "GET",
      { Authorization: accessToken }
    );

    localLogger("Response in getAPIKey function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in getAPIKey function:", err);
    throw err;
  }
};

// Get all user purchases
export const getUserPurchases = async (accessToken) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/purchases`,
      "GET",
      { Authorization: accessToken }
    );

    localLogger("Response in getUserPurchases function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in getUserPurchases function:", err);
    throw err;
  }
};

// Get trainer upload url(s)
// example filesArray:
// [
//   {
//     "file_type": "string", // "csv", "image", "video"
//     "filename": "string",
//     "folder": "string",
//     "content_type": "string"
//   }
// ]
export const generateTrainerUploadURLs = async (accessToken, filesArray) => {
  try {
    // Validate args
    if (!accessToken || !Array.isArray(filesArray) || !filesArray.length) {
      throw new Error("Missing required arguments.");
    }

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/generate_upload_url`,
      "POST",
      filesArray,
      {
        Authorization: accessToken,
      }
    );

    localLogger("Response in generateTrainerUploadURLs function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in generateTrainerUploadURLs function:", err);
    throw err;
  }
};

// Upload trainer data
export const uploadTrainerData = async (
  fileType, // "csv", "image", "video"
  folder,
  file,
  accessToken
) => {
  try {
    // Validate args
    apiValidateArguments({ fileType, file, folder, accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/upload_trainer_data?file_type=${fileType}&folder=${folder}`,
      "POST",
      file,
      {
        Authorization: accessToken,
      },
      false,
      false,
      false
    );

    localLogger("Response in uploadTrainerData function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in uploadTrainerData function:", err);
    throw err;
  }
};

// Get all user trainer directories
export const getUserTrainerFilePaths = async (accessToken, path) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${
        process.env.NEXT_PUBLIC_COMPOSER_URL
      }/v1/public/regional/auth/user/users/trainer_listing${
        path && "?path=" + path
      }`,
      "GET",
      { Authorization: accessToken }
    );

    localLogger("Response in getUserTrainerFilePaths function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in getUserTrainerFilePaths function:", err);
    throw err;
  }
};

// Get a user's uploaded trainer file itself
export const getUserTrainerFile = async (accessToken, path) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken, path });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/trainer_data?file_path=${path}`,
      "GET",
      { Authorization: accessToken }
    );

    localLogger("Response in getUserTrainerFile function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in getUserTrainerFile function:", err);
    throw err;
  }
};

// Delete trainer file
export const deleteUserTrainerFile = async (accessToken, path) => {
  try {
    // Validate args
    apiValidateArguments({ accessToken, path });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/file?file_full_path=${path}`,
      "DELETE",
      { Authorization: accessToken }
    );

    localLogger("Response in deleteUserTrainerFile function:", response);
    return response;

    // Handle errors
  } catch (err) {
    console.error("Error in deleteUserTrainerFile function:", err);
    throw err;
  }
};
