"use client";
import { useState, useEffect, useContext, createContext } from "react";
import {
  // getAuth,
  onIdTokenChanged,
  updatePassword as authUpdatePassword,
  sendPasswordResetEmail as authSendPasswordResetEmail,
  confirmPasswordReset as authConfirmPasswordReset,
  reauthenticateWithCredential as authReauthenticateWithCredential,
  sendEmailVerification as authSendEmailVerification,
  signInWithCustomToken,
  signOut,
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  updateEmail as authUpdateEmail,
} from "firebase/auth";
import { firebaseApp } from "./firebase";
import { startOAuthProcess } from "../api/userManagement";
import { getUserRoles } from "../api/userManagement";
// import withTimeout from "./timeout";
import { createUser } from "../api/userManagement";
import { login, logout } from "../api/loginManagement";
import getBrowserInfoForDb from "./getBrowserInfoForDb";
import PageLoader from "./../components/PageLoader";
import { localLogger, logger } from "./logger";
import clearLocalStorage from "./clearLocalStorage";
// import { getHealthStatus } from "../api/infrastructureManagement";

export const auth = (() => {
  // Check if the app is initialized before calling getAuth
  if (!firebaseApp) {
    return initializeAuth(firebaseApp, {
      persistence: [
        indexedDBLocalPersistence,
        browserLocalPersistence,
        browserSessionPersistence,
      ],
    });
  }
  return initializeAuth(firebaseApp, {
    persistence: [
      indexedDBLocalPersistence,
      browserLocalPersistence,
      browserSessionPersistence,
    ],
  });
})();

// App wrapper to provide all components with auth state & functions
const authContext = createContext(null);

export const useAuth = () => {
  const context = useContext(authContext);
  if (context === null) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Track user state & provide user state management functions
function useAuthProvider() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (firebaseUser) => {
      logger("Firebase token changed or removed");
      try {
        if (firebaseUser) {
          const token = await firebaseUser.getIdToken();
          localLogger("Token refreshed:", token);
          const consentRequired = localStorage.getItem("consent_required");
          const customUser = {
            ...firebaseUser,
            consent_required: consentRequired,
          };
          // Ensure the updated token is used in subsequent API requests
          setUser(customUser || false);
          refreshRoles(customUser);
        } else {
          logger("No Firebase user found. setting user to false");
          setUser(false);
        }
      } catch (err) {
        console.error("Error updating user:", err);
        setUser(false);
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Callable function for OAuth handler
  const callSignInWithCustomToken = async (firebaseToken, consentRequired) => {
    await signInWithCustomToken(auth, firebaseToken);
    localStorage.setItem("consent_required", consentRequired);
    const customUser = {
      ...auth.currentUser,
      consent_required: consentRequired,
    };
    setUser(customUser || false);
    return customUser;
  };

  // Sign in with email & password
  const signin = async (email, password) => {
    try {
      // const isHealthGood = await withTimeout(
      //   getHealthStatus(),
      //   10000,
      //   "Sorry, our robots have their hands full. 🤖😓 Please try again soon!"
      // );
      // if (!isHealthGood.data) {
      //   const serviceUnavailableError = new Error(
      //     "Sorry, our robots have their hands full. 🤖😓 Please try again soon!"
      //   );
      //   serviceUnavailableError.status = 503;
      //   throw serviceUnavailableError;
      // }
      // Get browser info to send with login
      let browserInfo;
      try {
        browserInfo = await getBrowserInfoForDb();
      } catch (err) {
        console.error("Error getting browser info:", err);
      }
      // login on backend & receive custom Firebase token
      const res = await login(email, password, browserInfo);
      const firebaseToken = res.data?.token;
      localLogger("signin token:", firebaseToken);
      // sync Firebase signin on frontend & set user object
      await signInWithCustomToken(auth, firebaseToken);
      const user = auth.currentUser;
      localLogger("User authenticated with Firebase:", user);
      // Save privacy consent status
      const consentRequired = res.data?.consent_required;
      localStorage.setItem("consent_required", consentRequired);
      const customUser = {
        ...user,
        consent_required: consentRequired,
      };
      setUser(customUser || false);

      return customUser;
    } catch (error) {
      console.error("Signin error:", error);
      if (error.status === 451) {
        window.location.href = "/region-unsupported";
      }
      throw error;
    }
  };

  // Sync user signin with Firebase after signup email auth success
  // const emailVerifiedSignin = async (firebaseToken) => {
  //   try {
  //   const auth = getAuth();
  //   await signInWithCustomToken(auth, firebaseToken);
  //   const user = auth.currentUser;
  //   logger("User authenticated with Firebase:", user);
  //   setUser(user);
  //   return user;
  // } catch (error) {
  //   console.error("Signin error:", error);
  //   throw error;
  // }
  // };

  // Create account with email & password
  const signup = async (email, password) => {
    const params = window.location.search;
    const res = await createUser(email, password, params || null);
    const user = res.data;
    // if (!user) {
    //   throw new Error("Did not receive user data.");
    // }
    setUser(user || false);
    return user;
  };

  // Sign in / sign up with provider (initiate OAuth on backend)
  const signinWithProvider = async (providerName, afterAuthPath) => {
    const params = window.location.search;
    await startOAuthProcess(providerName, params || null, afterAuthPath || "");
  };

  // Sign user out
  const signout = async () => {
    try {
      if (!auth?.currentUser) {
        throw new Error("Cannot find active user to logout");
      }
      // Sign out on backend
      const accessToken = auth.currentUser.accessToken;
      logger("Signing out from backend...");
      try {
        await logout(accessToken);
      } catch (err) {
        console.error("Backend error logging out, but proceeding:", err);
      }
      // Sync Firebase state on frontend (proceed even if fails)
      try {
        logger("Signing out from Firebase...");
        // Attempt Firebase sign out, wait only 10 seconds tops
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error("Firebase signout timed out"));
          }, 10000); // 10 seconds
        });
        await Promise.race([signOut(auth), timeoutPromise]);
      } catch (err) {
        console.error("Frontend Firebase signout failed, but proceeding:", err);
      }
      // Remove local storage items
      clearLocalStorage();
    } catch (err) {
      console.error(err);
      console.error(err.message);
      // throw err;
    } finally {
      // Remove user from state
      setUser(false);
      // eslint-disable-next-line no-unsafe-finally
      return user;
    }
  };

  // Reset password email via Firebase template (for forgotten password)
  const sendPasswordResetEmail = async (email) => {
    try {
      await authSendPasswordResetEmail(auth, email);
      logger("Password reset email sent successfully");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      throw error;
    }
  };

  const updateEmail = async (newEmail) => {
    try {
      await authUpdateEmail(auth.currentUser, newEmail);
      logger("Email updated successfully");
    } catch (error) {
      console.error("Error updating email:", error);
      throw error;
    }
  };

  // Confirm user completed the reset process
  const confirmPasswordReset = async (code, newPassword) => {
    try {
      await authConfirmPasswordReset(auth, code, newPassword);
      logger("Password has been reset successfully");
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  };

  // Handle user changing their password in app
  const updatePassword = async (newPassword) => {
    try {
      await authUpdatePassword(auth.currentUser, newPassword);
      logger("Password updated successfully");
    } catch (error) {
      console.error("Error updating password:", error);
      throw error;
    }
  };

  // Handle reauthentication
  const reauthenticateWithCredential = async (credential) => {
    try {
      await authReauthenticateWithCredential(auth.currentUser, credential);
      logger("Reauthenticated successfully");
    } catch (error) {
      console.error("Error reauthenticating:", error);
      throw error;
    }
  };

  // Handle user changing their password in app
  const sendEmailVerification = async () => {
    try {
      await authSendEmailVerification(auth.currentUser);
      logger("Sent email successfully");
    } catch (error) {
      console.error("Error sending verification email:", error);
      throw error;
    }
  };

  const refreshRoles = async (userCurrent = null) => {
    const thisUser = user || userCurrent;
    if (thisUser) {
      try {
        const res = await getUserRoles(thisUser.accessToken);
        const userRoles = res.data;
        logger("roles in refresh:", userRoles);
        setUser({ ...thisUser, roles: userRoles });
      } catch (error) {
        console.error("Error refreshing roles:", error);
        throw error;
      }
    }
  };

  return {
    user,
    callSignInWithCustomToken,
    signin,
    // emailVerifiedSignin,
    signup,
    signinWithProvider,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    reauthenticateWithCredential,
    sendEmailVerification,
    updateEmail,
    updatePassword,
    refreshRoles,
  };
}

// Export components with this if the user must be logged in to access the component
export const requireAuth = (Component) => {
  return function RequireAuthHOC(props) {
    const auth = useAuth();
    const user = auth.user;

    useEffect(() => {
      if (user === false) {
        window.location.href = "/";
      }
    }, [user]);

    if (user === null || user === undefined) {
      return <PageLoader />;
    }

    return user ? <Component {...props} /> : null;
  };
};

// // authUtils.js
// import { initiateOAuth, exchangeCodeForToken } from "./api"; // Import your API functions
// import { exchangeCodeForToken } from "./api"; // Import your API function
// import { getAuth, signInWithCustomToken } from "firebase/auth";

// // Function to initiate OAuth process
// export const startOAuthProcess = async (provider) => {
//   try {
//     const { oauthUrl } = await initiateOAuth(provider);
//     window.location.href = oauthUrl;
//   } catch (err) {
//     console.error("Error initiating OAuth:", err);
//     throw err; // Rethrow error to be handled by caller
//   }
// };

// // Function to handle OAuth callback
// export const handleOAuthCallback = async (code, state) => {
//   try {
//     const { firebaseToken } = await exchangeCodeForToken(code, state);

//     // Authenticate with Firebase using the custom token
//     const auth = getAuth();
//     await signInWithCustomToken(auth, firebaseToken);

//     // At this point, Firebase's user object is set and can be used throughout your app
//     const user = auth.currentUser;
//     logger("User authenticated with Firebase:", user);

//     return user;
//   } catch (err) {
//     console.error("Error handling OAuth callback:", err);
//     throw err;
//   }
// };
